import React, { useCallback, useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

const HeroSection = () => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isVideoVisible, setIsVideoVisible] = useState(false)
  const [isVideoLoading, setIsVideoLoading] = useState(true)
  const [videoError, setVideoError] = useState(false)
  const [retryCount, setRetryCount] = useState(0)
  const maxRetries = 3

  useEffect(() => {
    setIsVideoVisible(true)
  }, [])

  const handleVideoLoad = useCallback(() => {
    console.log('Video loaded successfully')
    setIsVideoLoading(false)
    setIsVideoVisible(true)
  }, [])

  const handleVideoError = useCallback((event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    console.error('Video loading error:', event)
    console.warn(`Video load attempt ${retryCount + 1} failed.`, event)
    
    if (retryCount < maxRetries) {
      setRetryCount(prev => prev + 1)
      setIsVideoVisible(false)
      setTimeout(() => setIsVideoVisible(true), 1000)
    } else {
      console.warn('Video failed to load after retries, falling back to static background')
      setVideoError(true)
      setIsVideoLoading(false)
      setIsVideoVisible(false)
    }
  }, [retryCount])

  return (
    <section className="relative min-h-screen">
      {/* Video Background Container */}
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-full overflow-hidden">
        <div 
          className={`absolute inset-0 w-full h-full transition-opacity duration-1000 ${isVideoVisible ? 'opacity-100' : 'opacity-0'}`}
        >
          {!videoError ? (
            <video
              ref={videoRef}
              className="absolute w-full h-full object-cover"
              style={{ 
                top: '0',
                left: '0'
              }}
              autoPlay
              loop
              muted
              playsInline
              onLoadedData={handleVideoLoad}
              onError={handleVideoError}
            >
              <source src="/videos/20241216_2346_Futuristic Orbital Station_simple_compose_01jf9saqxsep7acrd73bnfph3q.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <div className="absolute inset-0 bg-gradient-to-br from-stardrive-blue to-stardrive-blue/90">
              <div className="absolute inset-0">
                {[...Array(50)].map((_, i) => (
                  <div
                    key={i}
                    className="absolute w-[2px] h-[2px] bg-white rounded-full animate-twinkle"
                    style={{
                      left: `${Math.random() * 100}%`,
                      top: `${Math.random() * 100}%`,
                      animationDelay: `${Math.random() * 3}s`
                    }}
                    aria-hidden="true"
                  />
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Gradient Overlays */}
        <div className="absolute inset-0 bg-gradient-to-r from-stardrive-blue/95 via-stardrive-blue/70 to-transparent opacity-90"></div>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-stardrive-blue opacity-90"></div>
      </div>

      {/* Content Section */}
      <div className="relative z-10 min-h-screen flex flex-col justify-center max-w-7xl mx-auto px-8">
        <div className="space-y-8">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-5xl md:text-7xl lg:text-8xl font-bold text-white leading-tight"
          >
            Revolutionizing<br />
            <span className="text-stardrive-orange">Space Exploration</span><br />
            and Defense
          </motion.h1>
          
          <motion.p 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-xl md:text-2xl text-white/90 max-w-2xl leading-relaxed"
          >
            StarDrive combines breakthrough electromagnetic propulsion, advanced defense systems, 
            and adaptive technologies to reshape humanity's reach into the stars.
          </motion.p>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="flex flex-wrap gap-6"
          >
            <Link
              to="/technology"
              className="inline-flex items-center justify-center px-8 py-4 bg-stardrive-orange text-stardrive-blue rounded-full text-lg font-semibold hover:bg-opacity-90 transition-all duration-300 hover:scale-105"
            >
              Explore Our Technology
              <i className="fas fa-arrow-right ml-2"></i>
            </Link>
            <Link
              to="/contact"
              className="inline-flex items-center justify-center px-8 py-4 border-2 border-white/20 text-white rounded-full text-lg font-semibold hover:bg-white/10 transition-all duration-300"
            >
              Contact Us
              <i className="fas fa-arrow-right ml-2"></i>
            </Link>
          </motion.div>
        </div>

        {/* Scroll Indicator */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.2 }}
          className="absolute bottom-12 left-1/2 -translate-x-1/2"
        >
          <div className="flex flex-col items-center gap-2">
            <span className="text-white/60 text-sm">Scroll to explore</span>
            <motion.div
              animate={{ y: [0, 8, 0] }}
              transition={{ duration: 1.5, repeat: Infinity }}
              className="w-6 h-10 border-2 border-white/20 rounded-full flex justify-center p-2"
            >
              <motion.div
                animate={{ y: [0, 12, 0] }}
                transition={{ duration: 1.5, repeat: Infinity }}
                className="w-1.5 h-1.5 bg-white/60 rounded-full"
              />
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  )
}

export default HeroSection
