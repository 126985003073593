import React, { useState } from 'react';

interface OptimizedImageProps {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  className?: string;
}

const OptimizedImage: React.FC<OptimizedImageProps> = ({ 
  src, 
  alt, 
  width = 800, 
  height, 
  className 
}) => {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Use a fallback image if the original fails to load
  const fallbackImage = '/assets/placeholder-profile.png';

  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    console.error(`Failed to load image: ${src}`);
    // Try loading the image again with the correct extension
    const imgElement = e.target as HTMLImageElement;
    if (!hasError) {
      setHasError(true);
      // If the image path ends with .jpg, try .png instead
      if (src.endsWith('.jpg')) {
        imgElement.src = src.replace('.jpg', '.png');
      } else if (src.endsWith('.png')) {
        imgElement.src = src.replace('.png', '.jpg');
      } else {
        imgElement.src = fallbackImage;
      }
    } else {
      // If we've already tried both extensions, use the fallback
      imgElement.src = fallbackImage;
    }
    setIsLoading(false);
  };

  const handleLoad = () => {
    setIsLoading(false);
    setHasError(false);
  };

  return (
    <div className={`relative ${className || ''}`}>
      <img
        src={src}
        alt={alt}
        width={width}
        height={height}
        className={`${isLoading ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300 ${className}`}
        loading="lazy"
        onError={handleError}
        onLoad={handleLoad}
      />
      {isLoading && (
        <div 
          className="absolute inset-0 bg-gray-200 animate-pulse rounded-2xl"
          style={{ width: width ? `${width}px` : '100%', height: height ? `${height}px` : '100%' }}
        />
      )}
    </div>
  );
};

export default OptimizedImage;
