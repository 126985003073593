import React, { useState } from 'react'
import { motion } from 'framer-motion'

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: 'Business Inquiry',
    message: ''
  })

  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle')
  const [errorMessage, setErrorMessage] = useState('')

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setStatus('loading')
    setErrorMessage('')

    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(formData),
      })

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}))
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`)
      }

      const responseData = await response.json()
      console.log('Contact form submission successful:', responseData)

      setStatus('success')
      setFormData({
        name: '',
        email: '',
        subject: 'Business Inquiry',
        message: ''
      })

      // Reset success message after 5 seconds
      setTimeout(() => {
        setStatus('idle')
      }, 5000)
    } catch (error) {
      console.error('Contact form error:', error)
      setStatus('error')
      setErrorMessage(
        error instanceof Error 
          ? error.message 
          : 'Failed to send message. Please try again or email directly to jack@thestardrive.com'
      )
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  return (
    <div className="min-h-screen bg-stardrive-blue text-white p-8">
      <div className="max-w-4xl mx-auto space-y-12">
        {/* General Contact Information */}
        <motion.section 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="space-y-6"
        >
          <h1 className="text-4xl font-bold filtered-text">Contact Us</h1>
          <p className="text-lg filtered-text">For all inquiries, please use the form below or email us at <a href="mailto:jack@thestardrive.com" className="text-stardrive-orange">jack@thestardrive.com</a></p>
        </motion.section>

        {/* Subject Line Guidelines */}
        <motion.section 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="space-y-4"
        >
          <h2 className="text-2xl font-bold filtered-text">Subject Line Guidelines</h2>
          <div className="grid md:grid-cols-3 gap-6">
            <div className="bg-white/10 p-6 rounded-lg">
              <h3 className="text-xl font-bold mb-2 filtered-text">Partnerships</h3>
              <p className="filtered-text">Use [Partnership Inquiry] in your subject</p>
            </div>
            <div className="bg-white/10 p-6 rounded-lg">
              <h3 className="text-xl font-bold mb-2 filtered-text">Media</h3>
              <p className="filtered-text">Use [Media Inquiry] in your subject</p>
            </div>
            <div className="bg-white/10 p-6 rounded-lg">
              <h3 className="text-xl font-bold mb-2 filtered-text">Careers</h3>
              <p className="filtered-text">Use [Career Opportunities] in your subject</p>
            </div>
          </div>
        </motion.section>

        {/* Contact Form */}
        <motion.section 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="bg-white/5 p-8 rounded-lg"
        >
          <h2 className="text-2xl font-bold mb-6 filtered-text">Contact Form</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="name" className="block mb-2 filtered-text">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full p-3 rounded bg-white/10 border border-white/20 focus:border-stardrive-orange focus:outline-none filtered-text"
                disabled={status === 'loading'}
              />
            </div>
            <div>
              <label htmlFor="email" className="block mb-2 filtered-text">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full p-3 rounded bg-white/10 border border-white/20 focus:border-stardrive-orange focus:outline-none filtered-text"
                disabled={status === 'loading'}
              />
            </div>
            <div>
              <label htmlFor="subject" className="block mb-2 filtered-text">Subject</label>
              <select
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                className="w-full p-3 rounded bg-white/10 border border-white/20 focus:border-stardrive-orange focus:outline-none filtered-text"
                disabled={status === 'loading'}
              >
                <option value="Business Inquiry">Business Inquiry</option>
                <option value="Media Request">Media Request</option>
                <option value="Careers">Careers</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div>
              <label htmlFor="message" className="block mb-2 filtered-text">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows={5}
                className="w-full p-3 rounded bg-white/10 border border-white/20 focus:border-stardrive-orange focus:outline-none filtered-text"
                disabled={status === 'loading'}
              />
            </div>

            {/* Status Messages */}
            {status === 'success' && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-green-500/20 text-green-300 p-4 rounded-lg"
              >
                Message sent successfully! We'll get back to you soon.
              </motion.div>
            )}

            {status === 'error' && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-red-500/20 text-red-300 p-4 rounded-lg"
              >
                {errorMessage || 'Failed to send message. Please try again.'}
              </motion.div>
            )}

            <button
              type="submit"
              disabled={status === 'loading'}
              className={`bg-stardrive-orange text-stardrive-blue px-8 py-3 rounded font-bold transition-all duration-300 ${
                status === 'loading' 
                  ? 'opacity-70 cursor-not-allowed' 
                  : 'hover:bg-opacity-90 hover:scale-105'
              }`}
            >
              {status === 'loading' ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Sending...
                </span>
              ) : (
                'Send Message'
              )}
            </button>
          </form>
        </motion.section>
      </div>
    </div>
  )
}

export default ContactPage
