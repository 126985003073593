import OptimizedImage from '../components/OptimizedImage'
import { motion } from 'framer-motion'

const TeamPage = () => {
  const teamMembers = [
    {
      name: 'Jack Al-Kahwati',
      role: 'CEO',
      image: '/assets/Jack.png',
      details: [
        '20+ years in aerospace engineering and tech leadership',
        'Former CEO of Lattis, led teams at Boeing, BAE Systems, and Sikorsky',
        'MS in Aerospace Engineering, University of Florida',
        'Multiple patents in hardware technologies'
      ]
    },
    {
      name: 'Gerardo Barroeta',
      role: 'CTO',
      image: '/assets/Gerardo.png',
      details: [
        'MS in Electrical Engineering from MIT',
        'Led hardware teams at Juul Labs, BrewBird, Juicero, Jawbone, and Swift Navigation',
        'Expertise in IoT, sensors, and product development',
        'Multiple patents in hardware and sensing technologies'
      ]
    },
    {
      name: 'Steve Hong',
      role: 'Lead Mechanical Engineer',
      image: '/assets/Steve.png',
      details: [
        'Mechanical Design at Boeing',
        'Mechanical Design at NASA',
        'MS - Mechanical Eng. USF',
        'BS - Mechanical Eng. USF'
      ]
    },
    {
      name: 'Mian Hussain',
      role: 'Lead Systems Engineer',
      image: '/assets/Mian.png',
      details: [
        'Performance Engineer at Boeing',
        'BS - Mechanical Eng. Virginia Tech',
        'MS - Mechanical Eng. Virginia Tech',
        'MBA- Yale University'
      ]
    },
    {
      name: 'Dr. Martin Diz',
      role: 'Chief Scientist',
      image: '/assets/Martin.png',
      details: [
        'Ph.D in Aerospace Engineering at University of Buffalo',
        'CTO and co-founder at Bluesmart Luggage',
        'Y Combinator S20',
        'Developed micro-satellite attitude control devices flown by Virgin Galactic and NASA'
      ]
    },
    {
      name: 'Nino Marcantonio',
      role: 'Advisor',
      image: '/assets/Nino.png',
      details: [
        'Expert in defense tech innovation',
        'Former Deputy Director, Defense Technology Innovation Program',
        'Director, Defense Logistics Innovation Forum',
        'Facilitates DoD partnerships and technology integration'
      ]
    },
    {
      name: 'Peter Lai',
      role: 'Advisor',
      image: '/assets/Peter.png',
      details: [
        'Investor in 8 unicorn companies, specializing in high-growth technology startups',
        'Background in Applied Mathematics from UC Berkeley',
        'Expertise in venture capital, strategic growth, and market positioning',
        'Extensive network in the tech and mobility sectors, driving transformative partnerships'
      ]
    }
  ]

  return (
    <div className="min-h-screen bg-stardrive-blue text-white pt-24">
      <div className="max-w-7xl mx-auto px-4 py-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h1 className="text-7xl font-bold text-stardrive-orange mb-6">Team</h1>
          <p className="text-xl text-white/80 max-w-3xl mx-auto">
            Meet the innovators and industry leaders driving StarDrive's mission to revolutionize space transportation.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {teamMembers.map((member, index) => (
            <motion.div 
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="flex flex-col md:flex-row gap-8 bg-gradient-to-br from-gray-900/50 to-transparent p-8 rounded-3xl border border-stardrive-orange/20 hover:border-stardrive-orange/40 transition-all duration-500 group hover:shadow-lg hover:shadow-stardrive-orange/10"
            >
              <div className="w-full md:w-48 h-48 flex-shrink-0">
                <div className="relative w-full h-full overflow-hidden rounded-2xl">
                  <OptimizedImage
                    src={member.image}
                    alt={member.name}
                    className="object-cover w-full h-full transition-transform duration-500 group-hover:scale-110"
                  />
                </div>
              </div>
              <div className="flex-grow">
                <h3 className="text-3xl font-bold text-stardrive-orange mb-2">{member.name}</h3>
                <p className="text-xl text-white/90 font-medium mb-4">{member.role}</p>
                <ul className="space-y-2">
                  {member.details.map((detail, i) => (
                    <motion.li 
                      key={i}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: 0.2 + i * 0.1 }}
                      className="text-white/70 flex items-start gap-2"
                    >
                      <span className="text-stardrive-orange mt-1.5">•</span>
                      <span>{detail}</span>
                    </motion.li>
                  ))}
                </ul>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TeamPage
