import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'

const Footer = () => {
  const [email, setEmail] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [showCookieConsent, setShowCookieConsent] = useState(true)

  // Show back-to-top button when scrolled down
  React.useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }
    window.addEventListener('scroll', toggleVisibility)
    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const handleSubscribe = async (e: React.FormEvent) => {
    e.preventDefault()
    // Handle newsletter subscription
    console.log('Newsletter subscription:', email)
    setIsSubmitted(true)
    setTimeout(() => {
      setIsSubmitted(false)
      setEmail('')
    }, 3000)
  }

  return (
    <>
      <footer className="relative bg-gradient-to-b from-stardrive-blue to-stardrive-blue/95 py-16">
        <div className="container mx-auto px-8">
          <div className="grid grid-cols-1 md:grid-cols-6 gap-12">
            {/* Contact Information */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="space-y-4 md:col-span-2"
            >
              <h3 className="text-xl font-bold filtered-text mb-6">Contact</h3>
              <div className="space-y-2">
                <p className="filtered-text">StarDrive Headquarters</p>
                <p className="filtered-text">San Francisco, CA</p>
                <a 
                  href="mailto:jack@thestardrive.com" 
                  className="filtered-text hover:text-stardrive-orange transition-all duration-300 hover:translate-x-1 inline-block"
                >
                  jack@thestardrive.com
                </a>
                
                {/* Social Media Links */}
                <div className="flex space-x-6 pt-4">
                  <motion.a 
                    href="https://linkedin.com" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    whileHover={{ scale: 1.2, rotate: 5 }}
                    className="text-white hover:text-stardrive-orange transition-colors duration-300"
                  >
                    <i className="fab fa-linkedin text-2xl"></i>
                  </motion.a>
                  <motion.a 
                    href="https://twitter.com" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    whileHover={{ scale: 1.2, rotate: 5 }}
                    className="text-white hover:text-stardrive-orange transition-colors duration-300"
                  >
                    <i className="fab fa-twitter text-2xl"></i>
                  </motion.a>
                  <motion.a 
                    href="https://youtube.com" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    whileHover={{ scale: 1.2, rotate: 5 }}
                    className="text-white hover:text-stardrive-orange transition-colors duration-300"
                  >
                    <i className="fab fa-youtube text-2xl"></i>
                  </motion.a>
                </div>
              </div>
            </motion.div>

            {/* Quick Links */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className="space-y-4"
            >
              <h3 className="text-xl font-bold filtered-text mb-6">Quick Links</h3>
              <ul className="space-y-2">
                {[
                  { to: '/about', label: 'About Us' },
                  { to: '/technology', label: 'Technology' },
                  { to: '/solutions', label: 'Solutions' },
                  { to: '/careers', label: 'Careers' }
                ].map((link) => (
                  <motion.li key={link.to} whileHover={{ x: 5 }}>
                    <Link 
                      to={link.to} 
                      className="filtered-text hover:text-stardrive-orange transition-colors duration-300"
                    >
                      {link.label}
                    </Link>
                  </motion.li>
                ))}
              </ul>
            </motion.div>

            {/* Solutions */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="space-y-4"
            >
              <h3 className="text-xl font-bold filtered-text mb-6">Solutions</h3>
              <ul className="space-y-2">
                {[
                  { to: '/solutions#earth-to-orbit', label: 'Earth-to-Orbit Deployment' },
                  { to: '/solutions#orbital-logistics', label: 'Orbital Logistics' },
                  { to: '/astroshield', label: 'AstroShield' }
                ].map((link) => (
                  <motion.li key={link.to} whileHover={{ x: 5 }}>
                    <Link 
                      to={link.to} 
                      className="filtered-text hover:text-stardrive-orange transition-colors duration-300"
                    >
                      {link.label}
                    </Link>
                  </motion.li>
                ))}
              </ul>
            </motion.div>

            {/* Resources */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3 }}
              className="space-y-4"
            >
              <h3 className="text-xl font-bold filtered-text mb-6">Resources</h3>
              <ul className="space-y-2">
                {[
                  { to: '/media', label: 'Media Center' },
                  { to: '/partnerships', label: 'Partnerships' },
                  { to: '/contact', label: 'Contact Us' }
                ].map((link) => (
                  <motion.li key={link.to} whileHover={{ x: 5 }}>
                    <Link 
                      to={link.to} 
                      className="filtered-text hover:text-stardrive-orange transition-colors duration-300"
                    >
                      {link.label}
                    </Link>
                  </motion.li>
                ))}
              </ul>
            </motion.div>

            {/* Newsletter Subscription */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 }}
              className="space-y-4"
            >
              <h3 className="text-xl font-bold filtered-text mb-6">Stay Updated</h3>
              <form onSubmit={handleSubscribe} className="space-y-4">
                <div className="relative">
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    className="w-full px-4 py-3 rounded-lg bg-white/10 border border-white/20 text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-stardrive-orange transition-all duration-300"
                    required
                  />
                  <AnimatePresence>
                    {isSubmitted && (
                      <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className="absolute -top-10 left-0 right-0 text-green-400 text-sm"
                      >
                        Thanks for subscribing!
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
                <motion.button
                  type="submit"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="w-full px-4 py-3 bg-stardrive-orange text-stardrive-blue rounded-lg font-semibold hover:bg-opacity-90 transition-all duration-300"
                >
                  Subscribe to Newsletter
                </motion.button>
              </form>
            </motion.div>
          </div>

          {/* Legal Links with Privacy Notice */}
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="mt-16 pt-8 border-t border-gray-700"
          >
            <div className="flex flex-col space-y-6">
              {/* Copyright and Legal Links */}
              <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
                <p className="filtered-text">© {new Date().getFullYear()} StarDrive. All rights reserved.</p>
                <div className="flex flex-wrap justify-center md:justify-end gap-6">
                  <Link to="/legal/privacy-policy" className="filtered-text text-sm hover:text-stardrive-orange transition-colors duration-300">
                    Privacy Policy
                  </Link>
                  <Link to="/legal/terms-of-service" className="filtered-text text-sm hover:text-stardrive-orange transition-colors duration-300">
                    Terms of Service
                  </Link>
                  <Link to="/legal/cookie-policy" className="filtered-text text-sm hover:text-stardrive-orange transition-colors duration-300">
                    Cookie Policy
                  </Link>
                  <Link to="/legal/accessibility" className="filtered-text text-sm hover:text-stardrive-orange transition-colors duration-300">
                    Accessibility
                  </Link>
                </div>
              </div>

              {/* Privacy Notice */}
              <div className="text-sm text-white/60 max-w-4xl mx-auto text-center">
                <p>
                  Some U.S. state privacy laws offer their residents specific consumer privacy rights, which we respect as described in our privacy notice. 
                  To opt-out of our making available to third parties information relating to cookies and similar technologies for advertising purposes, 
                  select "Opt-Out". To exercise other rights you may have related to cookies, select "More Info" or see our{' '}
                  <Link to="/legal/privacy-policy#do-not-sell" className="text-stardrive-orange hover:underline">
                    "Do Not Sell My Personal Information"
                  </Link>
                  {' '}link.
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </footer>

      {/* Cookie Consent Banner */}
      <AnimatePresence>
        {showCookieConsent && (
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 100, opacity: 0 }}
            className="fixed bottom-0 left-0 right-0 bg-gray-900/95 backdrop-blur-sm text-white p-4 z-50"
          >
            <div className="container mx-auto max-w-7xl flex flex-col md:flex-row items-center justify-between gap-4">
              <div className="text-sm text-white/80">
                We use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic. 
                By clicking "Accept All", you consent to our use of cookies.
              </div>
              <div className="flex gap-3">
                <button
                  onClick={() => setShowCookieConsent(false)}
                  className="px-4 py-2 bg-white/10 hover:bg-white/20 rounded text-sm transition-colors duration-300"
                >
                  Opt-Out
                </button>
                <Link
                  to="/legal/cookie-policy"
                  className="px-4 py-2 bg-white/10 hover:bg-white/20 rounded text-sm transition-colors duration-300"
                >
                  More Info
                </Link>
                <button
                  onClick={() => setShowCookieConsent(false)}
                  className="px-4 py-2 bg-stardrive-orange text-stardrive-blue rounded text-sm font-semibold hover:bg-opacity-90 transition-colors duration-300"
                >
                  Accept All
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Back to Top Button */}
      <AnimatePresence>
        {isVisible && (
          <motion.button
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            onClick={scrollToTop}
            className="fixed bottom-8 right-8 p-4 bg-stardrive-orange text-stardrive-blue rounded-full shadow-lg hover:bg-opacity-90 transition-all duration-300 hover:scale-110 z-40"
            aria-label="Back to top"
          >
            <i className="fas fa-arrow-up"></i>
          </motion.button>
        )}
      </AnimatePresence>
    </>
  )
}

export default Footer
