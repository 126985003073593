import React from 'react';
import { motion } from 'framer-motion';

export const ShieldingAnimation = () => {
  return (
    <div className="relative w-full h-64 bg-stardrive-blue/30 rounded-lg overflow-hidden">
      {/* Spacecraft */}
      <motion.div
        className="absolute left-1/2 top-1/2 w-16 h-24 transform -translate-x-1/2 -translate-y-1/2"
        animate={{
          y: ["-52%", "-48%", "-52%"]
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        {/* Spacecraft Body */}
        <div className="absolute inset-0 bg-gradient-to-b from-gray-300 to-gray-400 rounded-lg" />
        
        {/* Engine Glow */}
        <motion.div
          className="absolute -bottom-2 left-1/2 w-6 h-6 bg-stardrive-orange rounded-full transform -translate-x-1/2 blur-sm"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.6, 0.8, 0.6]
          }}
          transition={{
            duration: 1,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
      </motion.div>

      {/* Shield Layers */}
      {[...Array(3)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute left-1/2 top-1/2 rounded-full border-2 border-stardrive-orange/30"
          style={{
            width: `${160 + i * 40}px`,
            height: `${160 + i * 40}px`,
            transform: 'translate(-50%, -50%)'
          }}
          animate={{
            scale: [1, 1.1, 1],
            opacity: [0.2, 0.4, 0.2],
            rotate: 360
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            delay: i * 0.5,
            ease: "linear"
          }}
        />
      ))}

      {/* Radiation Particles */}
      {[...Array(20)].map((_, i) => (
        <motion.div
          key={`particle-${i}`}
          className="absolute w-1 h-1 bg-red-500/50 rounded-full"
          initial={{
            x: Math.random() * 100 + "%",
            y: "-10%"
          }}
          animate={{
            y: "110%",
            opacity: [1, 0.5, 0],
            scale: [1, 0.5, 0]
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            delay: i * 0.2,
            ease: "linear"
          }}
        />
      ))}

      {/* Shield Energy Pulses */}
      {[...Array(4)].map((_, i) => (
        <motion.div
          key={`pulse-${i}`}
          className="absolute left-1/2 top-1/2 rounded-full bg-stardrive-orange/10"
          style={{
            width: '200px',
            height: '200px',
            transform: 'translate(-50%, -50%)'
          }}
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{
            scale: [0.8, 1.2],
            opacity: [0, 0.3, 0]
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            delay: i * 0.5,
            ease: "easeOut"
          }}
        />
      ))}

      {/* Shield Impact Points */}
      {[...Array(5)].map((_, i) => (
        <motion.div
          key={`impact-${i}`}
          className="absolute w-2 h-2 bg-stardrive-orange rounded-full"
          style={{
            left: `${20 + (i * 15)}%`,
            top: `${30 + (Math.sin(i) * 20)}%`
          }}
          animate={{
            scale: [0, 1, 0],
            opacity: [0, 1, 0]
          }}
          transition={{
            duration: 1,
            repeat: Infinity,
            delay: i * 0.3,
            ease: "easeOut"
          }}
        />
      ))}
    </div>
  );
};
