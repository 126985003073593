import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import HeroSection from './HeroSection'
import PartnersBar from './PartnersBar'
import OptimizedImage from './OptimizedImage'

const MainContent = () => {
  return (
    <main className="bg-stardrive-blue">
      <HeroSection />
      <PartnersBar />

      {/* Mission Section */}
      <section className="relative py-32">
        <div className="container mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="max-w-4xl mx-auto text-center space-y-8"
          >
            <h2 className="text-5xl font-bold text-stardrive-orange">Expanding the Boundaries of Possibility</h2>
            <p className="text-xl text-white/90 leading-relaxed">
              Our mission is to deliver cutting-edge solutions that redefine space exploration and defense. 
              From revolutionary propulsion to adaptive defense systems, StarDrive is building a future where 
              interstellar travel and orbital security are seamless realities.
            </p>
          </motion.div>
        </div>
      </section>

      {/* Technologies Grid */}
      <section className="py-32 bg-gradient-to-b from-stardrive-blue/95 to-stardrive-blue">
        <div className="container mx-auto px-4">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold text-stardrive-orange text-center mb-16"
          >
            Revolutionary Space Technologies
          </motion.h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {/* Electromagnetic Propulsion */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="group"
            >
              <div className="relative h-[300px] mb-6 overflow-hidden rounded-2xl">
                <OptimizedImage 
                  src="/assets/electromagnetic-propulsion.jpeg"
                  alt="Electromagnetic Propulsion"
                  className="w-full h-full object-cover transform group-hover:scale-110 transition-all duration-700"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
                <div className="absolute bottom-0 left-0 p-6">
                  <h3 className="text-2xl font-bold text-stardrive-orange mb-2">Electromagnetic Propulsion</h3>
                  <p className="text-white/90">Zero-fuel space transportation</p>
                </div>
              </div>
              <div className="space-y-4">
                <p className="text-white/80 leading-relaxed">
                  Revolutionary propulsion system enabling efficient Earth-to-orbit deployment without traditional fuel, 
                  drastically reducing launch costs.
                </p>
                <Link 
                  to="/technology" 
                  className="inline-flex items-center text-stardrive-orange hover:opacity-80 transition-all duration-300"
                >
                  Learn More
                  <i className="fas fa-arrow-right ml-2 transform group-hover:translate-x-1 transition-transform duration-300"></i>
                </Link>
              </div>
            </motion.div>

            {/* Orbital Logistics */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="group"
            >
              <div className="relative h-[300px] mb-6 overflow-hidden rounded-2xl">
                <OptimizedImage 
                  src="/assets/Orbital-Logistics.jpeg"
                  alt="Orbital Logistics"
                  className="w-full h-full object-cover transform group-hover:scale-110 transition-all duration-700"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
                <div className="absolute bottom-0 left-0 p-6">
                  <h3 className="text-2xl font-bold text-stardrive-orange mb-2">Orbital Logistics</h3>
                  <p className="text-white/90">Advanced space operations</p>
                </div>
              </div>
              <div className="space-y-4">
                <p className="text-white/80 leading-relaxed">
                  Advanced platforms enabling sustainable space operations, from manufacturing to 
                  material storage and interplanetary missions.
                </p>
                <Link 
                  to="/solutions" 
                  className="inline-flex items-center text-stardrive-orange hover:opacity-80 transition-all duration-300"
                >
                  Explore Solutions
                  <i className="fas fa-arrow-right ml-2 transform group-hover:translate-x-1 transition-transform duration-300"></i>
                </Link>
              </div>
            </motion.div>

            {/* AstroShield */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 }}
              className="group"
            >
              <div className="relative h-[300px] mb-6 overflow-hidden rounded-2xl">
                <OptimizedImage 
                  src="/assets/Astroshield.jpeg"
                  alt="AstroShield"
                  className="w-full h-full object-cover transform group-hover:scale-110 transition-all duration-700"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
                <div className="absolute bottom-0 left-0 p-6">
                  <h3 className="text-2xl font-bold text-stardrive-orange mb-2">AstroShield Defense</h3>
                  <p className="text-white/90">Next-generation protection</p>
                </div>
              </div>
              <div className="space-y-4">
                <p className="text-white/80 leading-relaxed">
                  Next-generation spacecraft protection with real-time threat detection and 
                  adaptive countermeasures.
                </p>
                <Link 
                  to="/astroshield" 
                  className="inline-flex items-center text-stardrive-orange hover:opacity-80 transition-all duration-300"
                >
                  Discover AstroShield
                  <i className="fas fa-arrow-right ml-2 transform group-hover:translate-x-1 transition-transform duration-300"></i>
                </Link>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Innovation Timeline */}
      <section className="py-32 relative">
        <div className="container mx-auto px-4">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold text-stardrive-orange text-center mb-16"
          >
            Innovation Timeline
          </motion.h2>
          
          <div className="max-w-4xl mx-auto">
            {[
              { year: '2023', event: 'StarDrive founded', delay: 0 },
              { year: '2024', event: 'Successful electromagnetic propulsion tests', delay: 0.2 },
              { year: '2025', event: "AstroShield integrated with Space Force's TAP Lab", delay: 0.4 },
              { year: '2026', event: 'Development of scalable lunar mass driver prototypes', delay: 0.6 }
            ].map((milestone, index) => (
              <motion.div 
                key={index}
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ delay: milestone.delay }}
                className="flex items-start gap-8 group mb-12 last:mb-0"
              >
                <div className="flex-shrink-0 w-32">
                  <span className="text-stardrive-orange font-bold text-2xl">{milestone.year}</span>
                </div>
                <div className="flex-grow">
                  <div className="h-0.5 bg-stardrive-orange/30 relative top-4">
                    <motion.div 
                      className="absolute left-0 w-4 h-4 bg-stardrive-orange rounded-full -translate-y-1/3"
                      whileHover={{ scale: 1.5 }}
                    />
                  </div>
                </div>
                <div className="flex-grow-[2]">
                  <p className="text-white/90 text-xl">{milestone.event}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Join Us Section */}
      <section className="py-32 bg-gradient-to-b from-stardrive-blue/95 to-stardrive-blue">
        <div className="container mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="max-w-4xl mx-auto text-center space-y-8"
          >
            <h2 className="text-4xl font-bold text-stardrive-orange">Join the Mission</h2>
            <p className="text-xl text-white/90 leading-relaxed">
              Be part of the team revolutionizing space exploration and defense. Work on groundbreaking 
              technologies that will shape humanity's future among the stars.
            </p>
            <div className="pt-8">
              <Link 
                to="/careers" 
                className="inline-flex items-center justify-center px-12 py-4 bg-stardrive-orange text-stardrive-blue rounded-full text-xl font-semibold hover:bg-opacity-90 transition-all duration-300 hover:scale-105"
              >
                View Open Positions
                <i className="fas fa-arrow-right ml-3"></i>
              </Link>
            </div>
          </motion.div>
        </div>
      </section>
    </main>
  )
}

export default MainContent
