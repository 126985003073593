import React from 'react'
import OptimizedImage from './OptimizedImage'

const PartnersBar = () => {
  return (
    <div className="w-full bg-gradient-to-r from-stardrive-blue via-stardrive-blue/95 to-stardrive-blue py-16">
      <div className="max-w-6xl mx-auto px-8">
        <h2 className="text-3xl font-bold mb-12 filtered-text text-center">Trusted By Industry Leaders</h2>
        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          {/* Space Force */}
          <div className="bg-white/5 p-8 rounded-lg flex flex-col items-center text-center hover:bg-white/10 transition-all duration-300 transform hover:scale-105">
            <OptimizedImage 
              src="/assets/Seal_of_the_United_States_Space_Force.svg.png"
              alt="Space Force Logo"
              className="h-32 w-auto object-contain mb-8"
            />
            <div className="space-y-3">
              <h3 className="text-2xl font-bold filtered-text">United States Space Force</h3>
              <p className="text-sm filtered-text text-white/80 max-w-sm">Strategic partner in advancing space defense capabilities and orbital operations.</p>
            </div>
          </div>
          {/* LongShot */}
          <div className="bg-white/5 p-8 rounded-lg flex flex-col items-center text-center hover:bg-white/10 transition-all duration-300 transform hover:scale-105">
            <OptimizedImage 
              src="/assets/LongShotLogoNew.png"
              alt="LongShot Logo"
              className="h-32 w-auto object-contain mb-8"
            />
            <div className="space-y-3">
              <h3 className="text-2xl font-bold filtered-text">LongShot</h3>
              <p className="text-sm filtered-text text-white/80 max-w-sm">Collaborating on advanced propulsion technologies and aerospace innovations.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PartnersBar
