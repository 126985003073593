import React from 'react';
import { motion } from 'framer-motion';
import OptimizedImage from '../components/OptimizedImage';
import { ShieldingAnimation } from '../components/ShieldingAnimation';
import { Link } from 'react-router-dom';

const AstroShieldPage: React.FC = () => {
  const features = [
    {
      title: "Real-Time Threat Detection",
      description: "Advanced sensor arrays and AI-driven analysis for immediate identification of potential hazards.",
      icon: "radar",
      details: ["Multi-spectrum scanning", "AI-powered analysis", "Millisecond response time"]
    },
    {
      title: "Adaptive Defense Systems",
      description: "Dynamic protection mechanisms that adjust to specific threats and environmental conditions.",
      icon: "shield-alt",
      details: ["Electromagnetic shielding", "Particle deflection", "Thermal protection"]
    },
    {
      title: "Integrated Command Center",
      description: "Centralized control system for monitoring and managing spacecraft protection.",
      icon: "satellite",
      details: ["Real-time monitoring", "Automated responses", "Mission control integration"]
    }
  ];

  return (
    <div className="min-h-screen bg-stardrive-blue text-white">
      {/* Hero Section */}
      <div className="relative h-[70vh] overflow-hidden">
        <div className="absolute inset-0">
          <OptimizedImage 
            src="/assets/Astroshield.jpeg"
            alt="AstroShield Defense System"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-stardrive-blue via-stardrive-blue/80 to-transparent"></div>
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-stardrive-blue"></div>
        </div>
        
        <div className="relative z-10 h-full flex items-center">
          <div className="container mx-auto px-4">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="max-w-3xl"
            >
              <h1 className="text-6xl font-bold text-stardrive-orange mb-6">AstroShield Defense</h1>
              <p className="text-2xl text-white/90 leading-relaxed">
                Advanced spacecraft protection system combining AI-driven threat detection 
                with adaptive defense mechanisms for unparalleled security in space.
              </p>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Interactive Demo Section */}
      <div className="py-24 bg-gradient-to-b from-stardrive-blue to-stardrive-blue/95">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="space-y-6"
            >
              <h2 className="text-4xl font-bold text-stardrive-orange mb-6">Interactive Shield Simulation</h2>
              <p className="text-lg text-white/90 leading-relaxed">
                Experience our advanced shielding technology in action. The simulation demonstrates 
                how AstroShield's multi-layered defense system protects spacecraft from various 
                threats in real-time.
              </p>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <i className="fas fa-check text-stardrive-orange mt-1.5 mr-3"></i>
                  <span className="text-white/80">Multi-layered electromagnetic barriers</span>
                </li>
                <li className="flex items-start">
                  <i className="fas fa-check text-stardrive-orange mt-1.5 mr-3"></i>
                  <span className="text-white/80">Real-time threat neutralization</span>
                </li>
                <li className="flex items-start">
                  <i className="fas fa-check text-stardrive-orange mt-1.5 mr-3"></i>
                  <span className="text-white/80">Adaptive shield modulation</span>
                </li>
              </ul>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="bg-stardrive-blue/20 p-8 rounded-2xl backdrop-blur-sm"
            >
              <ShieldingAnimation />
            </motion.div>
          </div>
        </div>
      </div>

      {/* Key Features Section */}
      <div className="py-24 bg-gradient-to-b from-stardrive-blue/95 to-stardrive-blue">
        <div className="container mx-auto px-4">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold text-stardrive-orange text-center mb-16"
          >
            Advanced Protection Features
          </motion.h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="bg-white/5 p-8 rounded-xl hover:bg-white/10 transition-all duration-300"
              >
                <div className="w-16 h-16 rounded-full bg-stardrive-orange/20 flex items-center justify-center mb-6">
                  <i className={`fas fa-${feature.icon} text-3xl text-stardrive-orange`}></i>
                </div>
                <h3 className="text-2xl font-bold text-stardrive-orange mb-4">{feature.title}</h3>
                <p className="text-white/80 mb-6">{feature.description}</p>
                <ul className="space-y-3">
                  {feature.details.map((detail, i) => (
                    <li key={i} className="flex items-center text-white/70">
                      <i className="fas fa-circle text-xs text-stardrive-orange mr-3"></i>
                      {detail}
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Integration Section */}
      <div className="py-24 bg-gradient-to-b from-stardrive-blue to-stardrive-blue/95">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="order-2 lg:order-1"
            >
              <OptimizedImage 
                src="/assets/Astroshield.jpeg"
                alt="AstroShield Integration"
                className="rounded-2xl shadow-2xl"
              />
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="order-1 lg:order-2 space-y-6"
            >
              <h2 className="text-4xl font-bold text-stardrive-orange">Seamless Integration</h2>
              <p className="text-lg text-white/90">
                AstroShield is designed to integrate seamlessly with existing spacecraft systems, 
                providing comprehensive protection without compromising performance or adding 
                significant mass to the vehicle.
              </p>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <i className="fas fa-check-circle text-stardrive-orange mt-1 mr-3"></i>
                  <span className="text-white/80">Compatible with all major spacecraft platforms</span>
                </li>
                <li className="flex items-start">
                  <i className="fas fa-check-circle text-stardrive-orange mt-1 mr-3"></i>
                  <span className="text-white/80">Minimal power requirements</span>
                </li>
                <li className="flex items-start">
                  <i className="fas fa-check-circle text-stardrive-orange mt-1 mr-3"></i>
                  <span className="text-white/80">Automated deployment and configuration</span>
                </li>
              </ul>
            </motion.div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="py-24 bg-gradient-to-b from-stardrive-blue/95 to-stardrive-blue">
        <div className="container mx-auto px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="max-w-3xl mx-auto"
          >
            <h2 className="text-4xl font-bold text-stardrive-orange mb-6">Ready to Protect Your Mission?</h2>
            <p className="text-xl text-white/80 mb-8">
              Contact us to learn how AstroShield can secure your space operations.
            </p>
            <Link
              to="/contact"
              className="inline-flex items-center justify-center px-8 py-4 bg-stardrive-orange text-stardrive-blue rounded-full text-lg font-semibold hover:bg-opacity-90 transition-all duration-300"
            >
              Get Started
              <i className="fas fa-arrow-right ml-2"></i>
            </Link>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default AstroShieldPage;
