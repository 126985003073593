import React from 'react'
import { motion } from 'framer-motion'
import OptimizedImage from '../components/OptimizedImage'
import { Link } from 'react-router-dom'

const SolutionsPage = () => {
  return (
    <div className="min-h-screen bg-stardrive-blue">
      {/* Hero Banner */}
      <div className="relative h-[60vh] overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-stardrive-blue via-stardrive-blue/70 to-transparent z-10"></div>
        <OptimizedImage 
          src="/assets/electromagnetic-propulsion.jpeg"
          alt="Space Solutions"
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="relative z-20 h-full flex items-center">
          <div className="container mx-auto px-4">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="max-w-3xl"
            >
              <h1 className="text-6xl font-bold text-stardrive-orange mb-6">Space Solutions</h1>
              <p className="text-2xl text-white/90 leading-relaxed">
                Pioneering the next generation of space technology through electromagnetic propulsion, 
                orbital logistics, and advanced defense systems.
              </p>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Solutions Grid */}
      <div className="container mx-auto px-4 py-24">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Earth-to-Orbit Card */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="group"
          >
            <div className="relative h-[300px] mb-6 overflow-hidden rounded-xl">
              <OptimizedImage 
                src="/assets/electromagnetic-propulsion.jpeg"
                alt="Electromagnetic Propulsion"
                className="w-full h-full object-cover transform group-hover:scale-110 transition-all duration-700"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
              <div className="absolute bottom-0 left-0 p-6">
                <h3 className="text-2xl font-bold text-stardrive-orange mb-2">Earth-to-Orbit Deployment</h3>
                <p className="text-white/90">Revolutionary electromagnetic propulsion system</p>
              </div>
            </div>
            <div className="space-y-4">
              <ul className="space-y-3">
                <li className="flex items-start">
                  <i className="fas fa-check text-stardrive-orange mt-1.5 mr-3"></i>
                  <span className="text-white/80">Zero-fuel propulsion technology</span>
                </li>
                <li className="flex items-start">
                  <i className="fas fa-check text-stardrive-orange mt-1.5 mr-3"></i>
                  <span className="text-white/80">Sustainable satellite deployment</span>
                </li>
                <li className="flex items-start">
                  <i className="fas fa-check text-stardrive-orange mt-1.5 mr-3"></i>
                  <span className="text-white/80">Reduced launch costs</span>
                </li>
              </ul>
              <Link 
                to="/technology"
                className="inline-flex items-center text-stardrive-orange hover:opacity-80 transition-all duration-300 mt-4"
              >
                Learn More
                <i className="fas fa-arrow-right ml-2"></i>
              </Link>
            </div>
          </motion.div>

          {/* Orbital Logistics Card */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="group"
          >
            <div className="relative h-[300px] mb-6 overflow-hidden rounded-xl">
              <OptimizedImage 
                src="/assets/Orbital-Logistics.jpeg"
                alt="Orbital Logistics"
                className="w-full h-full object-cover transform group-hover:scale-110 transition-all duration-700"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
              <div className="absolute bottom-0 left-0 p-6">
                <h3 className="text-2xl font-bold text-stardrive-orange mb-2">Orbital Logistics</h3>
                <p className="text-white/90">Advanced space operations and resource management</p>
              </div>
            </div>
            <div className="space-y-4">
              <ul className="space-y-3">
                <li className="flex items-start">
                  <i className="fas fa-check text-stardrive-orange mt-1.5 mr-3"></i>
                  <span className="text-white/80">In-space manufacturing</span>
                </li>
                <li className="flex items-start">
                  <i className="fas fa-check text-stardrive-orange mt-1.5 mr-3"></i>
                  <span className="text-white/80">Resource utilization</span>
                </li>
                <li className="flex items-start">
                  <i className="fas fa-check text-stardrive-orange mt-1.5 mr-3"></i>
                  <span className="text-white/80">Orbital transfer capabilities</span>
                </li>
              </ul>
              <Link 
                to="/technology#orbital"
                className="inline-flex items-center text-stardrive-orange hover:opacity-80 transition-all duration-300 mt-4"
              >
                Explore Platform
                <i className="fas fa-arrow-right ml-2"></i>
              </Link>
            </div>
          </motion.div>

          {/* AstroShield Card */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.3 }}
            className="group"
          >
            <div className="relative h-[300px] mb-6 overflow-hidden rounded-xl">
              <OptimizedImage 
                src="/assets/Astroshield.jpeg"
                alt="AstroShield"
                className="w-full h-full object-cover transform group-hover:scale-110 transition-all duration-700"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
              <div className="absolute bottom-0 left-0 p-6">
                <h3 className="text-2xl font-bold text-stardrive-orange mb-2">AstroShield Defense</h3>
                <p className="text-white/90">Next-generation spacecraft protection</p>
              </div>
            </div>
            <div className="space-y-4">
              <ul className="space-y-3">
                <li className="flex items-start">
                  <i className="fas fa-check text-stardrive-orange mt-1.5 mr-3"></i>
                  <span className="text-white/80">Real-time threat detection</span>
                </li>
                <li className="flex items-start">
                  <i className="fas fa-check text-stardrive-orange mt-1.5 mr-3"></i>
                  <span className="text-white/80">Adaptive countermeasures</span>
                </li>
                <li className="flex items-start">
                  <i className="fas fa-check text-stardrive-orange mt-1.5 mr-3"></i>
                  <span className="text-white/80">Asset protection systems</span>
                </li>
              </ul>
              <Link 
                to="/astroshield"
                className="inline-flex items-center text-stardrive-orange hover:opacity-80 transition-all duration-300 mt-4"
              >
                Discover AstroShield
                <i className="fas fa-arrow-right ml-2"></i>
              </Link>
            </div>
          </motion.div>

          {/* Lunar Mass Driver Card */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.4 }}
            className="group"
          >
            <div className="relative h-[300px] mb-6 overflow-hidden rounded-xl">
              <OptimizedImage 
                src="/assets/Lunar-Mass-Driver.jpeg"
                alt="Lunar Mass Driver"
                className="w-full h-full object-cover transform group-hover:scale-110 transition-all duration-700"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
              <div className="absolute bottom-0 left-0 p-6">
                <h3 className="text-2xl font-bold text-stardrive-orange mb-2">Lunar Mass Driver</h3>
                <p className="text-white/90">Lunar exploration and resource utilization</p>
              </div>
            </div>
            <div className="space-y-4">
              <ul className="space-y-3">
                <li className="flex items-start">
                  <i className="fas fa-check text-stardrive-orange mt-1.5 mr-3"></i>
                  <span className="text-white/80">Resource extraction</span>
                </li>
                <li className="flex items-start">
                  <i className="fas fa-check text-stardrive-orange mt-1.5 mr-3"></i>
                  <span className="text-white/80">Material transfer systems</span>
                </li>
                <li className="flex items-start">
                  <i className="fas fa-check text-stardrive-orange mt-1.5 mr-3"></i>
                  <span className="text-white/80">Lunar colony support</span>
                </li>
              </ul>
              <Link 
                to="/technology#lunar"
                className="inline-flex items-center text-stardrive-orange hover:opacity-80 transition-all duration-300 mt-4"
              >
                Explore Technology
                <i className="fas fa-arrow-right ml-2"></i>
              </Link>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Contact Section */}
      <div className="bg-gradient-to-r from-stardrive-blue to-stardrive-blue/90 py-24">
        <div className="container mx-auto px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="max-w-3xl mx-auto"
          >
            <h2 className="text-4xl font-bold text-stardrive-orange mb-6">Ready to Transform Space Operations?</h2>
            <p className="text-xl text-white/80 mb-8">
              Contact us to learn how our solutions can advance your space initiatives.
            </p>
            <Link
              to="/contact"
              className="inline-flex items-center justify-center px-8 py-4 bg-stardrive-orange text-stardrive-blue rounded-full text-lg font-semibold hover:bg-opacity-90 transition-all duration-300"
            >
              Get Started
              <i className="fas fa-arrow-right ml-2"></i>
            </Link>
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default SolutionsPage
